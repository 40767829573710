<template>
    <div class="card">
        <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
        <div v-if="!isSpinner">
            <div class="m-2">
                <b-row class="mb-1">
                    <b-col cols="12" md="6"            
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <h4 class="table_title" style="padding:0px;">Application Note List</h4>
                    </b-col>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
                        <v-select
                            v-model="perPage"
                            style="width: 90px"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="pageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                    </b-col>
                </b-row>
            </div>
            <b-table
                striped
                hover
                responsive
                show-empty
                v-model="applicationTable"
                empty-text="No matching records found"
                :per-page="perPage"
                :current-page="currentPage"
                :items="documentList"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="position-relative promotionalNotification applicationNoteMgnt"
            >
                <template #cell(id)="data">
                    {{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}
                </template>
                <template #cell(title)="data">
                    <div>
                        {{data.item.title}}
                    </div>
                </template>

                <template #cell(discription)="data">
                    {{data.item.discription}}
                </template>
                <template #cell(isdocenabled)="data">
                    <!-- {{data.item.isdocenabled ? 'Enabled' : 'Disabled'}} -->
                    <b-dropdown
                        :disabled="onlyRead"
                        :text="data.item.status == 0 ? 'Inactive' : 'Active'"
                    >
                    <b-dropdown-item :disabled="data.item.status == 0" @click="selectedStatus = 0,selectedId = data.item.id,$bvModal.show('modal-change-status-isActive')">Inactive</b-dropdown-item>
                    <b-dropdown-item :disabled="data.item.status == 1" @click="selectedStatus = 1,selectedId = data.item.id,changeStatusModelActive()">Active</b-dropdown-item>
                    </b-dropdown>
                </template>

                <template #cell(createdAt)="data">
                        {{dateFormat(data.item.createdAt)}}
                </template>
                <template #cell(action)="data">
                    <div class="d-flex align-items-center">
                        <img src='@/assets/images/erflog/Edit.png' :disabled="onlyRead" class="action_icon" @click.prevent="$router.push({ name: 'application-note-update', params: { id: data.item.id } })"/>
                        <img src='@/assets/images/erflog/Delete.png' v-if="data.item.status == 0" :disabled="onlyRead" class="action_icon" @click.prevent="selectedDeleteDoc = data.item,$bvModal.show('delete-information-document')"/>
                    </div>
                </template>
                <!-- <template #cell(action2)="data">
                    <div class="d-flex align-items-center">
                        <img src='@/assets/images/erflog/Download.png' v-if="data.item.attachment != ''" class="action_icon" @click="dowmloadDoc(data.item)"/>
                    </div>
                </template> -->
            </b-table>
            <div class="mx-2 mb-2">
                <div class="row">
                    <b-col md="6" class="d-flex align-items-center">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ perPage }} of {{ documentList.length }} entries</span>
                        <!-- <span class="text-muted mt-1">Showing {{ 1 }} to {{ perPage }} of {{ userData.employeeDocs.length }} entries</span> -->
                    </b-col>
                    <b-col  md="6">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="documentList.length"
                            :per-page="perPage"
                            align="right"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0 custom_pagination"
                        >
                        <template #prev-text>
                            <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                            />
                        </template>
                        </b-pagination>
                    </b-col>
                </div>
            </div>
            <b-modal
                id="delete-information-document"
                cancel-variant="outline-secondary"
                ok-title="Yes"
                cancel-title="No"
                centered
                title=""
                :no-close-on-backdrop="true"
                @hidden="selectedDeleteDoc = null"
                @cancel='isSpiner=false'
                @ok="deleteField()"
            >
                <div class="create_account_img">
                <img src="@/assets/images/erflog/DeletePopup.png" alt="">
                </div>
                <div style="text-align: center;" v-if="selectedDeleteDoc!=''">
                <label class="s_modal_title">Delete Note</label>
                <p>Are you sure you want to delete this application note?</p>
                </div>
            </b-modal>
            <b-modal
                id="modal-change-status-isActive"
                cancel-variant="outline-secondary"
                ok-title="Yes"
                ok-variant="success"
                cancel-title="No"
                centered
                title=""
                :no-close-on-backdrop="true"
                @ok="changeStatusModel"
                @cancel="cancelChangeModel"
            >
            <div class="create_account_img">
                <img src="../../../assets/images/erflog/Approve_Popup.png" alt="">
            </div>
            <div style="text-align: center;">
                <p>Are you sure you want to change status to Inactive ?</p>
            </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import {
    BCard,BFormInput,BFormGroup,BForm,BRow,BCol,BButton,BTabs,BTab,
    BCardText,BFormSelect,BFormDatepicker,BFormRadio,BFormFile,BSpinner,
    BTable, BAvatar, BBadge, BPagination, BInputGroup, BInputGroupAppend, BImg,BDropdownItem,
    BDropdown
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive'
import firebase from "@/utils/firebaseInit.js";
const db = firebase.firestore()
import { dbCollections } from "@/utils/firebaseCollection.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import firebaseTimeStamp from "firebase";
import moment from 'moment';
import * as uploadImageFile from '@/utils/FirebaseQueries/storageQuery/storageQueries';
export default {
   components: {
        BCard,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormSelect,
        BFormDatepicker,
        BFormRadio,
        BFormFile,
        BTabs,
        BTab,
        BSpinner,
        BTable, BAvatar, BBadge, BPagination, BInputGroup, BInputGroupAppend, BImg,
        vSelect,
        Ripple,
        BDropdownItem,
        BDropdown
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            perPage: 10,
            pageOptions: [10, 25, 50],
            applicationTable:[],
            totalRows: 1,
            currentPage: 1,
            filter: null,
            isSpinner:false,
            filterOn: [],
            selectedDeleteDoc:null,
            selectedEditDoc:null,
            fields: [
                {
                    key: 'title', label: 'Title',sortable: true
                },
                {
                    key: 'discription', label: 'Discription',sortable: true
                },
                {
                    key: 'createdAt', label: 'Create Time'
                }, 
                {
                    key: 'isdocenabled', label: 'Status'
                }
            ],
            documentList:[],
            onlyRead:false,
            dataArr:[],
            selectedId1 : '',
            selectedId : '',
            selectedStatus : '',
        }
    },
    methods: {
        changeStatusModelActive() {
            let updateObj = {
                status: this.selectedStatus,
                updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
            }

            db.collection(dbCollections.APPLICATIONNOTES).doc(this.selectedId).update(updateObj).then(() => {
                this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Application Note status changed successfully',
                            icon: 'CoffeeIcon',
                            variant: 'success',                               
                        },
                    });
                this.isSpinner = false;
                this.selectedId = '';
                this.selectedDeleteDoc = null;
            }).catch((error) => {
                this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: error.message,
                            icon: 'CoffeeIcon',
                            variant: 'danger',                               
                        },
                    });
                this.isSpinner = false;
                this.selectedId = '';
                this.selectedDeleteDoc = null;
            })
        },
        changeStatusModel() {
            let updateObj = {
                status: this.selectedStatus,
                updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
            }

            db.collection(dbCollections.APPLICATIONNOTES).doc(this.selectedId).update(updateObj).then(() => {
                this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Application Note status changed successfully',
                            icon: 'CoffeeIcon',
                            variant: 'success',                               
                        },
                    });;
                this.isSpinner = false;
                this.selectedId = '';
                this.selectedDeleteDoc = null;
            }).catch((error) => {
                this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: error.message,
                            icon: 'CoffeeIcon',
                            variant: 'danger',                               
                        },
                    });
                this.isSpinner = false;
                this.selectedId = '';
                this.selectedDeleteDoc = null;
            })
        },  
        cancelChangeModel() {
            this.selectedId = '';
            this.selectedStatus = '';
            this.$bvModal.hide('modal-change-status-Inactive')
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dowmloadDoc(fileObject){
            var self = this;
            self.$axios({
                url: fileObject.attachment,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
    
                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileObject.attachmentName);
                document.body.appendChild(fileLink);
    
                fileLink.click();
            })
            .catch(error=>{
                console.error(error)
            })
        },
        dateFormat(data) {
            return moment(new Date(data.seconds * 1000)).format("DD-MM-YYYY HH:mm")
        },
        deleteField(){
            let self = this;
            let objectDle = {...this.selectedDeleteDoc};
            let updateObj = {
                isDeleted: true,
                updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
            }
            db.collection(dbCollections.APPLICATIONNOTES).doc(objectDle.id).update(updateObj).then(()=>{
                self.isSpinner=false;
                self.$bvModal.hide('delete-information-document')
                self.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Application Note deleted successfully',
                        icon: 'CoffeeIcon',
                        variant: 'success',                               
                    },
                })
            }).catch(()=>{
                self.isSpinner=false;
                self.$bvModal.hide('delete-information-document')
                self.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Application Note deleted failed',
                        icon: 'CoffeeIcon',
                        variant: 'danger',                               
                    }
                })
            })
        }
    },
    computed: {
        dataMeta(){
            const localItemsCount = this.applicationTable ? this.applicationTable.length : 0
            return {
                from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + localItemsCount,
                of: this.documentList.length,
            }
        },
    },
    created() {
        let userAccess = JSON.parse(localStorage.getItem('userAccess'));
        if(userAccess) {
            let index = userAccess.findIndex((ele)=>{
                return ele.name === "Application Note Management"
            })
            if(index !== -1) {
                if(userAccess[index].allow.toLowerCase() == 'read') {
                    this.onlyRead = true;
                }
            }
        }
        if(!this.onlyRead){
            this.fields.push({key: 'action',label: 'Action',sortable: false})
        }
        try {     
            this.isSpinner = true;
            var self = this;
            db.collection(dbCollections.APPLICATIONNOTES).where("isDeleted",'==',false)
                .onSnapshot({includeMetadataChanges: true},(snapshot) => {
                if (snapshot.empty) {
                    this.isSpinner = false;
                    // console.error(snapshot);
                    self.documentList = [];
                    return;
                }
                snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                    if(change.type === "added") {
                        let index = self.documentList.findIndex((ele)=>{
                            return ele.id == change.doc.data().id
                        })
                        if(index > -1) {
                            let obj = {...change.doc.data()};
                            self.documentList[index] = obj
                        } else {
                            if(!change.doc.metadata.hasPendingWrites) {
                                let obj = {...change.doc.data()};
                                self.documentList.push(obj);
                            }
                        }
                    }
                    if(change.type === "modified") {
                        let index = self.documentList.findIndex((x) => x.id === change.doc.id);
                        if(index > -1) {
                            self.documentList[index] = {...change.doc.data()};
                        } 
                        else {
                            let obj = {...change.doc.data()};
                            self.documentList.push({...obj});
                        }
                    }
                    if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                        let index = self.documentList.findIndex((x) => x.id === change.doc.id);
                        if(index > -1) {
                            self.documentList.splice(index, 1);
                        }
                    }
                })
                self.documentList.sort((a,b)=>{
                    return b.createdAt.seconds - a.createdAt.seconds
                })
                self.$nextTick(()=>{
                    self.documentList = self.documentList.filter((ele)=>{
                        return ele.isDeleted == false
                    })
                    self.$forceUpdate();
                    self.isSpinner = false
                })
            })
        } catch (error) {
            console.error(error);
            this.isSpinner = false;
        }
    },
}
</script>
<style>
.position-relative.applicationNoteMgnt.table-responsive .table tr th:nth-child(3) {
    width: 15% !important;
}
.position-relative.applicationNoteMgnt.table-responsive .table tr th:nth-child(2) {
    width: 45% !important;
}
</style>